import React from "react"
import Button from "../button"
import Faq from "./faq"
import * as styles from "./faqs.module.css"

const Faqs = ({faqs, headline}) => {
    return (
        <div className={styles.faqs}>
            {headline ? 
                <div className={`flex space-between align-start ${styles.header}`}>
                    <h3 className="section-header">{headline}</h3>
                    <Button 
                        type="purpleMarchingAnts" 
                        text="See all FAQs" 
                        newTab={false} 
                        url="/frequently-asked-questions"
                    />
                </div>
                : <></>
            }
            {faqs.map((faq, index) =>
                <Faq 
                    key={index}
                    index={(index%4)+1}
                    question={faq.question}
                    answerColumns={faq.answer}
                />
            )}
        </div>
    )
}

export default Faqs