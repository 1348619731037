import React from "react"
import * as styles from "./styles.module.css"

const LegalBanner = ({ title, backgroundImage, mobileBackgroundImage }) => {
    return (
        <>
        <div style={{
            backgroundImage: `url(${backgroundImage})`
        }} className={`${styles.shapes__bg} ${styles.desktop_bg}`}>
            <h1 className="visuallyhidden">
                {title}
            </h1>
        </div>
        {mobileBackgroundImage ? 
            <div style={{
                backgroundImage: `url(${mobileBackgroundImage})`
            }} className={`${styles.mobile_bg} ${styles.shapes__bg} ${styles.no_headline}`}>
                <h1 className="visuallyhidden">{title}</h1>
            </div>
            : 
            ""
        }
        </>
    )
}

export default LegalBanner