import React, { useState } from "react"
import * as styles from "./faq.module.css"

const Faq = ({index, question, answerColumns}) => {
    const [expand, setExpand] = useState(false)
    const expandFaq = () => {
        setExpand(!expand)
    }
    const numColumns = 12 / answerColumns.length
    return (
        <div className={`${index === 1 ? styles.key1 : index === 2 ? styles.key2 : index === 3 ? styles.key3 : styles.key4} ${styles.content} ${expand ? styles.expanded : ""}`}>
            <button
                onClick={expandFaq} 
                className={styles.faq}>{question}</button>
            <div className={`${styles.answer} ${expand ? styles.expand : ""}`}>
                <div className={`${styles.container} container-fluid`}>
                    <div className="row">
                        {answerColumns.map((column, index) =>
                            <div
                                key={index} 
                                className={`col-md-${numColumns}`}
                                dangerouslySetInnerHTML={{ __html: column.text }}>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq