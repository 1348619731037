import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import LegalBanner from "../components/legalBanner"
import { Seo } from "../components/seo"
import Faqs from "../components/faqs"
import Cta from "../components/cta"

export default function FaqPage({ data: { page } }) {
  return (
    <Layout>
        <LegalBanner 
          title={page.title}
          backgroundImage={page.bannerBackgroundImage.url}
          mobileBackgroundImage={page.mobileBackgroundImage.url}
          />
          <div className="container pt-120 pb-120 pt-60-tablet pb-60-tablet">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <Faqs faqs={page.faqs}/>
              </div>
            </div>
          </div>
          {page.cta.map((block, index) =>
            <Cta key={index} data={block} type={`${block.model.apiKey === "shapes_cta" ? "shapes" : "default"}`}/>
          )}
    </Layout>
  )
}


export const data = graphql`
  query FaqQuery {
    page: datoCmsFrequentlyAskedQuestionsPage {
      title
      bannerBackgroundImage {
        url
      }
      mobileBackgroundImage {
        url
      }
      faqs {
        question
        answer {
          text
        }
      }
      cta {
        ... on DatoCmsShapesCta {
          model {
            apiKey
          }
          headline
          body
          buttonText 
          buttonUrl
          newTab
          shapeType
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  return (
    <Seo 
      title={data.page.seo.title}
      description={data.page.seo.description}
      image={data.page.seo.image}
    />
  )
}
