// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var colors = "\"../../assets/css/_colors.css\"";
export var faqs = "faqs-module--faqs--c4f59";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var header = "faqs-module--header--bdf40";
export var lightBg = "#FFF8E8";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var yellowBtn = "#FFE07B";