// extracted by mini-css-extract-plugin
export var answer = "faq-module--answer--afdf3";
export var blackish = "#121117";
export var colors = "\"../../../assets/css/_colors.css\"";
export var container = "faq-module--container--cfc7b";
export var content = "faq-module--content--aebc3";
export var expand = "faq-module--expand--49c7f";
export var expanded = "faq-module--expanded--964b2";
export var faq = "faq-module--faq--9b2bc";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var key1 = "faq-module--key1--fbc11";
export var key2 = "faq-module--key2--b769b";
export var key3 = "faq-module--key3--72a22";
export var key4 = "faq-module--key4--fe6e1";
export var lightBg = "#FFF8E8";
export var lightGreyBg = "#EAEAEA";
export var lightPurple = "#E1DBFF";
export var lightYellowBg = "#FFF0BD";
export var mediumOrange = "#FFB96F";
export var periwinkle = "#665CFF";
export var purple = "undefined";
export var yellowBtn = "#FFE07B";